// 一些公共方法封装
import Global from "@/views/dwg/global";

export default {
  // 节流
  throttle(fn, interval) {
    var enterTime = 0; //触发的时间
    var gapTime = interval || 3000; //间隔时间，如果interval不传值，默认为3000ms
    return function() {
      var that = this;
      var backTime = new Date(); //第一次函数return即触发的时间
      if(backTime - enterTime > gapTime) {
        fn.apply(that, arguments);
        enterTime = backTime; //赋值给第一次触发的时间 保存第二次触发时间
      }
    };
  },

  winBlankOpen(url) {
    var body = document.body;
    let aLink = document.createElement('div');
    aLink.innerHTML = '<a target="_blank" rel="noopener noreferrer" href='+url+' id="j_winBlankOpen"></a>';
    body.appendChild(aLink);
    var j_winBlankOpen = document.getElementById('j_winBlankOpen');
    j_winBlankOpen.click();
    aLink.remove();
  },

  convert_size_MB(osize) {
    if (osize == 104857600) {
      return '100M';
    } else if (osize == 1073741824) {
      return '1G';
    } else if (osize == 10737418240) {
      return '10G';
    }

    var size = (osize / (1024 * 1024)).toFixed(2); // MB

    var sizestr = size + "";
    var len = sizestr.indexOf("\."); // eslint-disable-line
    var dec = sizestr.substr(len + 1, 2);
    if (dec == "00") { //当小数点后为00时 去掉小数部分  
      return sizestr.substring(0, len) + sizestr.substr(len + 3, 2) + 'M';
    }
    return sizestr + 'M';
  },

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) { return pair[1]; }
    }
    return '';
  },

  getQueryVariable1(url) {
    if (url.indexOf('?') != -1) {
      let obj = {};
      let arr = url.slice(url.indexOf('?') + 1).split('&');
      arr.forEach(item => {
        let param = item.split('=');
        obj[param[0]] = param[1];
      })
      // console.log(obj);//{type: "2", a: "3"}
      return obj;
    } else {
      console.log('没有参数');
      return null;
    }
  },

  getBit(flags, bit) {
    /*1 return true; 0 return false*/
    return flags & bit ? true : false;
  },
  setBit(flags, bit, value) {
    return value ? (flags |= bit) : (flags &= ~bit);
  },
  clearBit(val, bit) {
    var a = 255;
    for (var i = 0; i <= bit; i++) {
      a = a << 1;
      if (i > 0) a++;
    }
    return val & a;
  },
  reverseBit(val, bit) {
    if (this.getBit(val, bit)) return this.clearBit(val, bit);
    else return this.setBit(val, bit);
  },

  is_time_Expired(data, endData) {
    var _data = data;
    for (var i = 0; i < _data.length; i++) {
      var start_date = new Date(_data.replace(/-/g, "/"));
      var end_date = new Date();
      // console.log('start_date', start_date.getTime(), 'end_date', end_date.getTime())
      var days = end_date.getTime() - start_date.getTime();
      var day = parseInt(days / (1000 * 60 * 60 * 24));
      if (day >= endData) {
        return true;
      } else if (day < endData) {
        return false;
      }
    }
  },

  //退出清除缓存
  del_localStorae() {
    window.localStorage.removeItem("USER_INFO");
    window.localStorage.removeItem("USERTOKEN");
    window.localStorage.removeItem("USER_INFO_TIME");
  },
  //验证是否为手机号
  isPhoneNo(value) {
    var rex = /^1[34578]\d{9}$/;// /^1[3-9]+\d{9}$/;
    if (rex.test(value)) {
      return true;
    } else {
      return false;
    }
  },

  //归类目录路径
  pathToTree(input) {
    if (input.length == 0) return;
    let root = [];
    for (let i = 0; i < input.length; i++) {
      let chain = input[i].split("/");
      let currentHierarchy = root;
      for (let j = 0; j < chain.length; j++) {
        let wantedNode = chain[j];
        if (wantedNode === "") {
          continue;
        }

        let lastHierarchy = currentHierarchy;
        // 遍历root是否已有该层级
        for (let k = 0; k < currentHierarchy.length; k++) {
          if (currentHierarchy[k].label === wantedNode) {
            currentHierarchy = currentHierarchy[k].children;
            break;
          }
        }

        if (lastHierarchy === currentHierarchy) {
          let newNode = {
            // key: key,
            routeName: input[i],
            label: wantedNode,
            children: [],
          };
          currentHierarchy.push(newNode);
          currentHierarchy = newNode.children;
        }
      }
    }

    return root;
  },


  //时间转换
  convert_date_day(dateValue) {
    var date = new Date(dateValue.replace(/-/g, "/"));
    var date_time = date.getTime() / 1000;

    var t_time = Date.parse(new Date()) / 1000;

    var second_ago = t_time - date_time;

    if (second_ago < 60) { // 小于1分钟
      return '刚刚';
    } else if (second_ago < 3600) { // 小于1小时
      return '' + Math.floor(second_ago / 60) + '分钟前';
    } else if (second_ago < 86400) { // 小于1天
      return '' + Math.floor(second_ago / 3600) + '小时前';
    } else if (second_ago < 604800) { // 小于1周
      return '' + Math.floor(second_ago / 86400) + '天前';
    } else {
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + (date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes());
    }
  },

  // 参数 str 为时间戳 可以传入10位也可以传入13位
  // 参数 bool的值可传true或者false或者不传，如果需要显示秒则传true，不需要显示则传false或者不传
  getMyDate(str, bool) {
    var c_Date;
    var c_Time;
    if (str > 9999999999) { // 这里判断：时间戳为几位数
      c_Date = new Date(parseInt(str));
    } else {
      c_Date = new Date(parseInt(str) * 1000);
    }
    var c_Year = c_Date.getFullYear(),
      c_Month = c_Date.getMonth() + 1,
      c_Day = c_Date.getDate(),
      c_Hour = c_Date.getHours(),
      c_Min = c_Date.getMinutes(),
      c_Sen = c_Date.getSeconds();
    if (bool) { // 判断是否需要显示秒
      c_Time = c_Year + '-' + this.getzf(c_Month) + '-' + this.getzf(c_Day) + ' ' + this.getzf(c_Hour) + ':' + this.getzf(c_Min) + ':' + this.getzf(c_Sen);//最后拼接时间 
    } else {
      c_Time = c_Year + '-' + this.getzf(c_Month) + '-' + this.getzf(c_Day) + ' ' + this.getzf(c_Hour) + ':' + this.getzf(c_Min);//最后拼接时间 
    }
    return c_Time;
  },
  //补0操作  小于10的就在数字前面加0，这应该很好理解吧

  getzf(c_num) {
    if (parseInt(c_num) < 10) {
      c_num = '0' + c_num;
    }
    return c_num;
  },
  // 用法：
  // 需要显示秒：getMyDate(1523927510, true)
  // 不需要显示秒：① getMyDate(1523927510, false) ② getMyDate(1523927510)
  // 如果只需要时间： getMyDate(1523927510, true).split(" ")[1];
  // 如果只需要日期： getMyDate(1523927510, true).split(" ")[0];  

  compareTime(prop, align) {
    return function (a, b) {
      var value1 = a[prop];
      var value2 = b[prop];
      if (align == "positive") {//正序
        return new Date(value1) - new Date(value2);
      } else if (align == "inverted") {//倒序
        return new Date(value2) - new Date(value1);
      }
    }
  },
  //秒转换为天时分秒
  formatSeconds(value) {
    var theTime = parseInt(value);// 需要转换的时间秒 
    var theTime1 = 0;// 分 
    var theTime2 = 0;// 小时 
    var theTime3 = 0;// 天
    if (theTime > 60) {
      theTime1 = parseInt(theTime / 60);
      theTime = parseInt(theTime % 60);
      if (theTime1 > 60) {
        theTime2 = parseInt(theTime1 / 60);
        theTime1 = parseInt(theTime1 % 60);
        if (theTime2 > 24) {
          //大于24小时
          theTime3 = parseInt(theTime2 / 24);
          theTime2 = parseInt(theTime2 % 24);
        }
      }
    }
    var result = '';
    if (theTime > 0) {
      result = "" + parseInt(theTime) + "秒";
    }
    if (theTime1 > 0) {
      result = "" + parseInt(theTime1) + "分" + result;
    }
    if (theTime2 > 0) {
      result = "" + parseInt(theTime2) + "小时" + result;
    }
    if (theTime3 > 0) {
      result = "" + parseInt(theTime3) + "天" + result;
    }
    return result;
  },


  isIE(that) {
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      that.$router.push({
        name: "not_support",
      });
      return true;
    } else {
      return false;
    }
  },

  //实现函数如下所示
  getBrowser(that, n) {
    var ua = navigator.userAgent.toLowerCase(),
      s,
      name = '',
      ver = 0;
    //探测浏览器
    (s = ua.match(/msie ([\d.]+)/)) ? _set("ie", _toFixedVersion(s[1])) :
      (s = ua.match(/firefox\/([\d.]+)/)) ? _set("firefox", _toFixedVersion(s[1])) :
        (s = ua.match(/chrome\/([\d.]+)/)) ? _set("chrome", _toFixedVersion(s[1])) :
          (s = ua.match(/opera.([\d.]+)/)) ? _set("opera", _toFixedVersion(s[1])) :
            (s = ua.match(/version\/([\d.]+).*safari/)) ? _set("safari", _toFixedVersion(s[1])) : 0;


    function _toFixedVersion(ver, floatLength) {
      ver = ('' + ver).replace(/_/g, '.');
      floatLength = floatLength || 1;
      ver = String(ver).split('.');
      ver = ver[0] + '.' + (ver[1] || '0');
      ver = Number(ver).toFixed(floatLength);
      return ver;
    }
    function _set(bname, bver) {
      name = bname;
      ver = bver;
      console.log(name)
      console.log(ver)
      if (name == 'chrome' && ver < 85.0 && !isWeiXina()) {
        that.$router.push({
          name: "not_support",
        });
      }
      if (name == 'firefox' && ver < 63.0 && !isWeiXina()) {
        that.$router.push({
          name: "not_support",
        });
      }

    }

    function isWeiXina(){
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if(ua.match(/MicroMessenger/i) == 'micromessenger'){
        return true;
      } else{
        return false;
      }
    }
    return (n == 'n' ? name : (n == 'v' ? ver : name + '-' + ver));
  },
  
  // RGB转16
  setRgbTo16(str){
    let reg = /^(rgb|RGB)/;
    if(!reg.test(str)){return;}
    // 将str中的数字提取出来放进数组中
    var arr = str.slice(4, str.length-1).split(",");
    let c = '#';
    for(var i = 0; i < arr.length; i++){
        // Number() 函数把对象的值转换为数字
        // toString(16) 将数字转换为十六进制的字符表示
        var t = Number(arr[i]).toString(16);
        //如果小于16，需要补0操作,否则只有5位数
        if(Number(arr[i]) < 16){
            t = '0' + t;
        }
        c += t;
    }
    return c;
  },

  //js使用十六进制颜色生成RGB信息
  getNumber(color) {
    return (color.charAt(0) == "#") ? color.substring(1, 7) : color
  },

  getR(color) {
    return parseInt((this.getNumber(color)).substring(0, 2), 16)
  },

  getG(color) {
    return parseInt((this.getNumber(color)).substring(2, 4), 16)
  },

  getB(color) {
    return parseInt((this.getNumber(color)).substring(4, 6), 16)
  },

  //文件流编码
  Utf8ArrayToStr(array) {
    let str = '';
    let i = 0;
    const len = array.length;
    let char1 = 0;
    let char2 = 0;
    let char3 = 0;
    let char4 = 0;
    while (i < len) {
      char1 = array[i += 1];
      // 当单个字节时, 最大值 '01111111', 最小值 '00000000' 右移四位 07, 00
      // 当两个字节时, 最大值 '11011111', 最小值 '11000000' 右移四位 13, 12
      // 当三个字节时, 最大值 '11101111', 最小值 '11100000' 右移四位 14, 14
      if (char1 >> 4 <= 7) {
        str += String.fromCharCode(char1);
      } else if (char1 >> 4 === 12 || char1 >> 4 === 13) {
        char2 = array[i += 1];
        str += String.fromCharCode(((char1 & 0x1F) << 6) | (char2 & 0x3F));
      } else if (char1 >> 4 === 14) {
        char2 = array[i += 1];
        char3 = array[i += 1];
       char4 = ((char1 & 0x0F) << 12) | ((char2 & 0x3F) << 6);
        str += String.fromCharCode(char4 | ((char3 & 0x3F) << 0));
      }
    }
    return str;
  },

  // 获取文件名
  getFileName(name) {
    return name.substring(0, name.lastIndexOf("."))
  },
  // 获取 .后缀名
  getExtensiona(name) {
    return name.substring(name.lastIndexOf("."))
  },
  // 只获取后缀名
  getExtension(name) {
    return name.substring(name.lastIndexOf(".") + 1)
  },

  get_str_number(str) {
    return str.replace(/[^0-9]/gi, "");
  },
  get_aci_color_name(str) {
    var property_colors = {
      BYLAYER: { aci: "256", color: "255,255,2555" },
      BYBLOCK: { aci: "0", color: "255,255,255" },
      红: { aci: "1", color: "255,0,0" },
      黄: { aci: "2", color: "255,255,0" },
      绿: { aci: "3", color: "0,255,0" },
      青: { aci: "4", color: "0,255,255" },
      蓝: { aci: "5", color: "0,0,255" },
      洋红: { aci: "6", color: "255,0,255" },
      白: { aci: "7", color: "255,255,255" },
      深灰: { aci: "8", color: "128,128,128" },
      灰: { aci: "9", color: "192,192,192" },
    };
    for (var name in property_colors) {
      if (str == property_colors[name]["aci"]) {
        return name;
      }
    }
    return false;
  },
  get_aci_color_show(str) {
    var property_colors = {
      BYLAYER: { aci: "256", color: "255,255,255" },
      BYBLOCK: { aci: "0", color: "255,255,255" },
      红: { aci: "1", color: "255,0,0" },
      黄: { aci: "2", color: "255,255,0" },
      绿: { aci: "3", color: "0,255,0" },
      青: { aci: "4", color: "0,255,255" },
      蓝: { aci: "5", color: "0,0,255" },
      洋红: { aci: "6", color: "255,0,255" },
      白: { aci: "7", color: "255,255,255" },
      深灰: { aci: "8", color: "128,128,128" },
      灰: { aci: "9", color: "192,192,192" },
    };
    for (var name in property_colors) {
      if (str == property_colors[name]["aci"]) {
        return property_colors[name]["color"];
      }
    }
    return false;
  },

  getXx(val) {
    var list_xx = Global.list_xx
    for (const name in list_xx) {
      if (val == list_xx[name]["text"]) {
        return list_xx[name]
      }
    }
  },
  getXx1(val) {
    var list_xx = Global.list_xx
    for (const name in list_xx) {
      if (val == list_xx[name]["value"]) {
        return list_xx[name]
      }
    }
  },

  getXk(val) {
    var list_xk1 = Global.list_xk1
    for (const name in list_xk1) {
      if (val == "跟随图层" && list_xk1[name]["text"] == "跟随图层") {
        return list_xk1[name]
      }
      if (val == "跟随图块" && list_xk1[name]["text"] == "跟随图块") {
        return list_xk1[name]
      }
      if (val == "默认" && list_xk1[name]["text"] == "默认") {
        return list_xk1[name]
      }
      if (val == list_xk1[name]["int"]) {
        return list_xk1[name]
      }
    }
  },

  set_hide_phone(phone) {
    var tel = phone;
    tel = "" + tel;
    var ary = tel.split("");
    ary.splice(3, 5, "****");
    var tel1 = ary.join("");
    return tel1
  },

  //下载客户端
  down_kehuduan() {
    var agent = navigator.userAgent.toLowerCase();
    var curWindosVer = '32'
    var isMac = function() {
      return /macintosh|mac os x/i.test(navigator.userAgent);
    }();
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
      curWindosVer = '32'
    }else if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
      curWindosVer = '64'
    }
    console.log(curWindosVer)
    if (curWindosVer == '32') {
      window.open('https://down.appol.com/softwares/MiniCADSee.exe', '__black')
		} else {
      window.open('https://down.appol.com/softwares/MiniCADSee_X64.exe', '__black')
		}
  },

  isWeiXin(){
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if(ua.match(/MicroMessenger/i) == 'micromessenger'){
      return true;
    } else{
      return false;
    }
  },

  isMobile() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
  },

  getCaption(obj,state) {
    var index=obj.lastIndexOf("\-");
    if(state==0){
      obj=obj.substring(0,index);
    } else {
      obj=obj.substring(index+1,obj.length);
    }
      return obj;
  },

  //结束命令
  onCancelCmd() {
    if (viewer.IsInCmd()) {
      viewer.EndCmd(1, 2, 3);
      viewer.unselect();
      viewer.update();
    }
  },
  //右键结束命令隐藏窗口
  rightClickHideView() {
    try {
      vue.$store.state.show_del_type = false
      vue.$store.state.is_show_BiaozhuSet = false
      vue.$store.state.is_show_BiaogaoSet = false
      vue.$store.state.is_show_MergeBlock = false
      vue.$store.state.is_in_quick_cmd_cmd_input = false
      vue.$store.state.is_in_quick_cmd_input = false
      vue.$store.state.cloud_line_status = false
      vue.$store.state.show_insert_table = false
      vue.$store.state.show_insert_table2 = false
      vue.$store.state.depot_edit.state = false
      // vue.$store.state.pattern_fill = false
    } catch (error) {}
  },

  rotationAnimate(obj, target, callback, time) {
    var ani_time = time || 20; // 缓动的时间
    clearInterval(obj.timer);
    obj.timer = setInterval(function() {
      var step = (target - obj.offsetLeft) / 10;
      step = step > 0 ? Math.ceil(step) : Math.floor(step);
      if (obj.offsetLeft == target) {
        clearInterval(obj.timer);
        callback && callback()
      } else {
        obj.style.left = obj.offsetLeft + step + 'px';
      }
    },ani_time)
  },

  b64Encode(str) {
    return btoa(encodeURIComponent(str));
  },
  b64Decode(str) {
    return decodeURIComponent(atob(str));
  },

  set_bloburl_copy_dwg(bloburl) {
    window.localStorage.setItem('bloburl', bloburl)
  },
  set_bloburl_copy_dwg_from(bloburlfrom) {
    window.localStorage.setItem('bloburlfrom', bloburlfrom)
  },
  set_bloburl_copy_dwg_from_fid(f_id) {
    window.localStorage.setItem('bloburlfromfid', f_id)
  },
}


