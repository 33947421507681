// 获取云盘请求模块

import request from '@/utils/request'
import qs from 'qs'
// 获取最近打开
export const get_recent_file = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudrecent/recent_getlist',
    data: qs.stringify(data)
  })
}

// 最近打开 -- 删除
export const deleteRecent = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudrecent/recent_del',
    data: qs.stringify(data)
  })
}

// 获取我的图纸
export const getFileList = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/get_file_list_new',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 打开
export const openCloudfile = (data) => {
  return request({
    method: 'POST',
    async: false,
    url: '/cloud/online/open_cloudfile',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 打开后添加到最近列表
export const recent_add = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudrecent/recent_add',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 打开后添加到最近列表
export const cloudlink_add = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudlink/cloudlink_add',
    data: qs.stringify(data)
  })
}

//收藏
export const myCloudCollection_yes = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudcollect/cc_add',
    data: qs.stringify(data)
  })
}

//取消收藏
export const myCloudCollection_no = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudcollect/cc_del',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 重命名
export const myCloudUpdateName = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/renamefile',
    data: qs.stringify(data)
  })
}

//我的图纸 -- cloudlink重命名
export const cloudlink_updname = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudlink/cloudlink_updname',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 下载
export const myCloudDown = (data) => {
  return request({
    async: true,
    method: 'POST',
    url: '/cloud/cloudapi/get_download_url',
    data: qs.stringify(data)
  })
}

//我的图纸 -- link下载
export const myCloudLinkDown = (data) => {
  return request({
    async: true,
    method: 'POST',
    url: '/cloud/cloudapi/get_link_download_url',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 删除
export const myCloudDel = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/deleteFile',
    data: qs.stringify(data)
  })
}

//我的图纸 -- cloudlink删除
export const cloudlink_del = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudlink/cloudlink_del',
    data: qs.stringify(data)
  })
}


//我的图纸 -- 复制
export const copyfile = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/copyfile',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 复制 cloudlink
export const cloudlink_copy = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudlink/cloudlink_copy',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 粘贴及移动

//我的图纸 -- 批量删除
export const myCloudDels = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/deleteFile_m',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 批量收藏文件
export const collect_cloudfile_m = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/collectapi/collect_cloudfile_m',
    data: qs.stringify(data)
  })
}


//我的图纸 -- 新建图纸 -- 获取文件夹
export const getDirList = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/getDirList',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 新建图纸
export const createNewDwg = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/create_new_dwg',
    data: qs.stringify(data)
  })
}

//我的图纸 -- 新建文件夹
export const createDirectory = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/createDirectory',
    data: qs.stringify(data)
  })
}


// 我的图纸 -- 移动文件群组
export const move_team_file = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/upload_byself_cloudfile',
    data: qs.stringify(data)
  })
}

// 我的图纸 -- 移动文件云盘
export const move_cloud_file = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/movefile',
    data: qs.stringify(data)
  })
}

// 我的图纸 -- 移动文件cloudlink
export const cloudlink_upddir = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudlink/cloudlink_upddir',
    data: qs.stringify(data)
  })
}


//打开团队图纸
export const openTeamfile = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/open_cloudfile',
    data: qs.stringify(data)
  })
}

// 获取我的收藏
export const getCollectionFile = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudcollect/cc_getlist',
    data: qs.stringify(data)
  })
}

// 上传单个文件
export const upload_dwg = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/cloudapi/upload_cloudfile',
    data: data
  })
}

// 获取我的群
export const get_teams = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/get_teams',
    data: data
  })
}

// 获取我的群详情
export const get_team_data = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/get_team_data',
    data: data
  })
}

// 我的群文件 -- 收藏
export const collect_team_cloudfile = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/collectapi/collect_team_cloudfile',
    data: data
  })
}
// 我的群文件 -- 取消收藏
export const cancel_collect_team_cloudfile = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/collectapi/cancel_collect_team_cloudfile',
    data: data
  })
}

// 我的群文件 -- 删除
export const myteam_deleteFile = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/deleteFile',
    data: data
  })
}

// 我的群 -- 新建群
export const create_team = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/create_team',
    data: data
  })
}

// 我的群 -- 修改群名称
export const update_team_name = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/rename_team',
    data: data
  })
}

// 我的群 -- 退出 解散群
export const break_team = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/break_team',
    data: data
  })
}

// 我的群 -- 查看群成员
export const get_team_users = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/get_team_users',
    data: data
  })
}


// 我的群 -- 设置成员权限
export const upd_team_user_pmsion = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/upd_team_user_pmsion',
    data: data
  })
}

// 我的群 -- 删除群成员
export const delete_team_user = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/delete_team_user',
    data: data
  })
}

// 我的群 -- 群成员获取链接
export const create_team_code = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/create_team_code',
    data: data
  })
}

// 我的群 -- 下载文件
export const get_download_url = (data) => {
  return request({
    async: true,
    method: 'POST',
    url: '/cloud/myteam/get_download_url',
    data: data
  })
}

// 我的群 -- 编辑群公告
export const update_ttop = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/update_ttop',
    data: data
  })
}

// 获取加入群信息
export const get_join_code = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/myteam/get_join_code',
    data: data
  })
}

// 加入群
export const jion_team_api = (data) => {
  return request({
    method: 'POST',
    url: 'cloud/myteam/join_team',
    data: data
  })
}


// 上传单个文件
export const upload_excel = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/excel/exce_xml',
    data: data
  })
}