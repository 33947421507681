import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/index'
import Layout from "@/views/layout/index.vue";
import Recently_opened from "@/views/recently_opened/index";
import My_drawings from "@/views/my_drawings/index";
import My_collection from "@/views/my_collection/index";

import My_team from "@/views/my_team/index";
import CAD_PDF from "@/views/cad_pdf/index";
import CAD_IMG from "@/views/cad_img/index";
import CAD_VER from "@/views/cad_ver/index";
import CAD_TZ from "@/views/cad_tz/index";
import MShare from "@/views/mshare/index";

// import store from "../store/index";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cloud/online/dwg',
    name: 'olddwg',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwg/dwgdraw.vue')
  },
  {
    path: '/cloud/online/cdf',
    name: 'oldcdf',
    component: () => import(/* webpackChunkName: "about" */ '@/views/cdf/index')
  },
  {
    path: '/cloud1',
    component: Layout,
    children: [
      {
        path: '/recently_opened',
        name: 'recently_opened',
        component: Recently_opened
      },
      {
        path: '/my_drawings',
        name: 'my_drawings',
        component: My_drawings
      },
      {
        path: '/my_collection',
        name: 'my_collection',
        component: My_collection
      },
      {
        path: '/my_team',
        name: 'my_team',
        component: My_team
      },
      {
        path: '/cad_pdf',
        name: 'cad_pdf',
        component: CAD_PDF
      },
      {
        path: '/cad_img',
        name: 'cad_img',
        component: CAD_IMG
      },
      {
        path: '/cad_ver',
        name: 'cad_ver',
        component: CAD_VER
      },
      {
        path: '/cad_tz',
        name: 'cad_tz',
        component: CAD_TZ
      },
    ]
  },
  {
    path: '/dwgsee',
    name: 'dwgsee',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwg/index.vue')
  },
  {
    path: '/dwg',
    name: 'dwg',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwg/dwgdraw.vue')
  },
  {
    path: '/dwgmenusfour',
    name: 'dwgmenusfour',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwg/dwg_menus_four/block.vue')
  },
  {
    path: '/cdf',
    name: 'cdf',
    component: () => import(/* webpackChunkName: "about" */ '@/views/cdf/index')
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: () => import(/* webpackChunkName: "about" */ '@/views/pdf/index')
  },
  {
    path: '/privatecloudsdk',
    name: 'privatecloudsdk',
    component: () => import(/* webpackChunkName: "about" */ '@/views/private_cloud_sdk/index.vue')
  },
  {
    path: '/usercenter',
    name: 'usercenter',
    component: () => import(/* webpackChunkName: "about" */ '@/views/usercenter/index.vue')
  },

  {
    path: '/teamjion',
    name: 'teamjion',
    component: () => import(/* webpackChunkName: "about" */ '@/views/my_team_jion/index')
  },
  {
    path: '/mshare',
    name: 'mshare',
    component: MShare
  },
  {
    path: '/not_support',
    name: 'not_support',
    component: () => import(/* webpackChunkName: "about" */ '@/views/help_page/not_support')
  },
  {
    path: '/wap',
    name: 'wap',
    component: () => import(/* webpackChunkName: "about" */ '@/views/wap/index')
  },
  {
    path: '/bip',
    name: 'bip',
    component: () => import(/* webpackChunkName: "about" */ '@/views/bip/index')
  },
  {
    path: '/i/:share_id',
    name: 'i',
    component: () => import(/* webpackChunkName: "about" */ '@/views/i/index')
  },
  {
    path: '/l/:share_id',
    name: 'l',
    component: () => import(/* webpackChunkName: "about" */ '@/views/l/index')
  },
  {
    path: '/dwgdb',
    name: 'dwgdb',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dwgdb/index')
  },
  {
    path: '*',  // 重定向，防止错误路径
    redirect: '/recently_opened'
  }

]


const router = new VueRouter({
  mode: 'history',
  routes,
  base: '/', //配置nginx访问结果
})


router.beforeEach(async (to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  next(); 
}); 


// // 路由拦截，判断是否需要登录
// router.beforeEach((to, from, next) => {
//   // if (to.meta.title) {
//   // //判断是否有标题
//   // document.title = to.meta.title;
//   // }
//   // console.log("title",document.title)
//   // 通过requiresAuth判断当前路由导航是否需要登录
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     let token = localStorage.getItem('USERTOKEN')
//     console.log("token",token)
//     // 若需要登录访问，检查是否为登录状态
//     if (!token) {
//       next({
//         path: '/',
//         query: { redirect: to.fullPath }
//       })
//     } else {
//       next()
//     }
//   } else {
//     next() // 确保一定要调用 next()
//   }
// })


export default router
