<template>
  <div class="upload-container-show" :class="$store.state.is_show_up_all ? '' : 'upload-container-show-hide' ">
    <div class="upload-container">
      <div class="tooltip-title">
        <div class="tooltip-title-left">上传完成</div>
        <div class="tooltip-title-right">
          <img
            id="toolti_up_down_btn"
            data-open="1"
            src="@/assets/images/common/jilu_down.png"
            :title="!$store.state.is_show_up_all ? '展开记录' : '收起记录'"
            @click="click_is_show_all"
          />
          <div id="tooltip-del" @click="clearUpFileHistory">清空记录</div>
          <div class="tooltip-title-exit" @click="$store.state.is_show_up_history = false">
            <img src="@/assets/images/team/exit.png" alt="" />
          </div>
        </div>
      </div>

      <div class="tooltip-body" v-show="$store.state.is_show_up_all">
        <div
          class="tooltip-item"
          v-for="(item,index) in $store.state.waitFileList"
          :key="index"
        >
          <div class="tooltip-item-img">
            <img :src="item.type == 'dir' ? dirImg : fileImg" style="width:26px"/>
          </div>
          <div class="tooltip-item-file">
            <span class="tooltip-item-file-name">{{item.name}}</span>
            <span class="tooltip-item-file-size" v-if="item.type != 'dir'">{{item.size}}</span>
            <span class="tooltip-item-file-size" v-if="item.type == 'dir'">{{item.path}}</span>
          </div>
          <div
            class="search_dir_path"
            title="打开所在文件夹"
            @click="open_file_path(item)"
            :class="item.statusText == '失败' || item.statusText == '' ? 'tooltip-item-open-hide' : 'tooltip-item-open-show'"
          >
            <img src="@/assets/images/common/jilu_dir.png" />
          </div>
          <div
            class="tooltip-item-item-action gray"
            :class="item.statusText == '成功' ? 'green' : 'red'"
          >
            {{item.statusText}}
          </div>
          <div class="tooltip-item-file-right">
            <img
              class="upload-tip-img"
              :src="item.statusImg"
              v-if="item.statusText == '成功' || item.statusText == '失败'"
            />
            <i v-if="item.statusText == ''" class="el-icon-loading"></i>
          </div>
        </div>
      </div>
      <div class="tooltip-body-use-box" v-show="$store.state.is_show_up_all">
        <div class="tooltip-body-use-position">
          <div class="toolitip-body-first">
            <div class="toolitip-body-first-text">
              个人空间<span style="padding-left: 15px" class="cloud-usage"
                >{{$store.state.usage }}</span
              >/<span class="cloud-total">{{ $store.state.total }}</span>
            </div>
            <div class="toolitip-body-first-btn-flex">
              <div
                class="toolitip-body-first-btn"
                id="toolitip-body-first-btn-qingli"
                @click="clearCloud"
              >
                清理
              </div>
              <div
                class="toolitip-body-first-btn"
                id="toolitip-body-first-btn-buy-vip"
                @click="buyCloud"
              >
                扩充
              </div>
            </div>
          </div>
          <!-- <div class="tooltip-body_use_full_line"></div> -->
          <!-- <div
            class="tooltip-body_use_full_line1"
            style="background-color: rgb(67, 131, 240); width: 365.4px"
          ></div> -->
          <el-progress :show-text="false" :percentage="$store.state.percentage" :color="$store.state.customColors"></el-progress>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'upload_history',
  data() {
    return {
      activeNames: ["1"],
      is_show_all: true,
      successImg: require('@/assets/images/cloud/collection.png'),
      fileImg: require("@/assets/images/cloud/file.png"),
      dirImg: require("@/assets/images/cloud/dir1.png"),
    };
  },
  computed: {
    waitFileList(){
      return this.$store.state.waitFileList
    }
  },

  mounted () {

  },
  methods: {
    handleChange(val) {
      console.log(val);
    },

    click_is_show_all() {
      this.$store.state.is_show_up_all = !this.$store.state.is_show_up_all  
    },
    // 清空上传记录
    clearUpFileHistory () {
      this.$store.state.waitFileList = []
    },

    clearCloud () {
      this.$msg.white('赶快去我的图纸清理一下吧')
    },

    //打开文件所在位置
    open_file_path(item) {
      if (item.type == 'file') {
        this.$store.state.cur_parent_dir = item.path
        // 切换标签页
        this.$EventBus.$emit("checkLeftMenu", 'my_drawings')
        this.$EventBus.$emit("getMyTableData");
      } else if (item.type == 'dir') {
        this.$store.state.cur_parent_dir = item.path
        this.$EventBus.$emit("checkLeftMenu", 'my_drawings')
        this.$EventBus.$emit("getMyTableData");
      }

      this.set_update_cloud_path(item.path)
    },

    //重写我的图纸面包屑
    set_update_cloud_path (path) {
      var paths = path.split('/').filter(s=>s).map((item,index,array)=>({text:item,path: '/' + array.slice(0,index+1).join('/')}))
      var defaultPath = [{ text: "我的图纸", path: "/" }]
      this.$store.state.cur_cloud_paths = [...defaultPath, ...paths]
      
    },
    
    buyCloud () {
      this.$store.state.is_show_pay = true
      this.$store.state.mask = true
    },
  },
};
</script>

<style lang="less" scoped>

.el-progress {
 width: 420px;
 margin: auto;
}

/deep/ .el-progress .el-progress-bar__outer {
 background: #ced0d1;
}

.tooltip-item-open-hide {
  visibility: hidden;
}

.tooltip-item-open-show {
  visibility: visible;
}


.tooltip-item-item-action {
  width: 45px;
  text-align: center;
}

.tooltip-item .green {
  color: green;
}

.gray {
  color: gray;
}

.tooltip-item .red {
  color: red
}

.upload-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  transition: bottom 0.3s ease;
  transition: height 0.2s ease;
  overflow: hidden;
  width: 450px;
  height: 430px;
}

.tooltip-title {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: 78px;
  padding: 0 30px;
}

.tooltip-body {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  font-size: 13px;
  overflow: auto;
  position: relative;
  max-height: calc(100% - 80px - 60px);
}

.tooltip-item {
  padding: 0 30px;
  position: relative;
  height: 67px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.upload-container-show {
  position: fixed;
  width: 450px;
  height: 430px;
  z-index: 48;
  bottom: 30px;
  right: 100px;
  border: 1px solid rgba(98, 107, 132, 0.2);
  border-radius: 2px;
  box-shadow: 0 0 6px 0 rgb(98 107 132 / 20%);
  background-color: #fff;
  transition: height 0.2s linear 0s;
}

.upload-container-show-hide {
  height: 75px;
  transition: height 0.2s linear 0s;
}

.tooltip-title-left {
  font-size: 18px;
  color: #3d4757;
}

.tooltip-title-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

#tooltip-del {
  /* margin-right: 46px; */
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}

.tooltip-title-exit {
  margin-left: 16px;
  cursor: pointer;
}

.tooltip-title-exit img {
  width: 30px;
  height: 30px;
}

.tooltip-item-file-right {
  display: flex;
}

.tooltip-item-file-right img {
  width: 16px;
  height: 16px;
}

.tooltip-item-file {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: flex-start;
  width: 260px;
  margin-left: 20px;
  overflow: hidden;
}

.tooltip-item-file-name {
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

.tooltip-item-file-size {
  font-size: 12px;
  color: #949aae;
}

/* .tooltip-item-img img {
    width: 30px;
    height: 30px;
  } */

.tooltip-title:after,
.tooltip-item:after {
  background-color: #eee;
}

.tooltip-title:after,
.tooltip-item:after {
  content: "";
  display: block;
  width: calc(100% - 60px);
  height: 1px;
  position: absolute;
  bottom: 0;
}

#toolti_up_down_btn {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.tooltip-item-xuanzhuan {
  -webkit-animation: run 6s linear 0s infinite;
}

.tooltip-item-xuanzhuan:hover {
  -webkit-animation-play-state: paused;
}

@-webkit-keyframes run {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.tooltip-body-use-box {
  position: absolute;
  bottom: 0;
  background-color: #f0f4fc;
  width: 100%;
  height: 60px;
  user-select: none;
}

.toolitip-body-first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 420px;
  margin: 5px 15px;
}

.toolitip-body-first-btn-flex {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolitip-body-first-btn {
  width: 40px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #4383f0;
  font-size: 15px;
  cursor: pointer;
}

.toolitip-body-first-btn:hover {
  color: red;
}

.tooltip-body_use_full_line,
.tooltip-body_use_full_line1 {
  position: absolute;
  left: 15px;
}

.tooltip-body_use_full_line {
  width: 420px;
  height: 6px;
  background-color: #fff;
  border-radius: 2px;
  z-index: 1;
}

.tooltip-body_use_full_line1 {
  background-color: #4383f0;
  width: 10%;
  z-index: 2;
  height: 6px;
  border-radius: 2px;
}

.toolitip-body-first-text {
  color: #999;
  font-size: 14px;
}

.tooltip-body-use-position {
  position: relative;
}


</style>