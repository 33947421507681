import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  //属性一：state相当于vue中的data
  // data的特性:响应式 所以state也具有这一特性
  //响应式：当在data或state中的数据发生改变时，页面视图也随之发生改变
  state: {
    //login userinfo
    is_login: false,
    userinfo: {},
    is_show_login: false,


    is_show_bindwx_get_code_box: false, //个人中心绑定微信前获取验证码
    cur_bindwx_action: 'bind', //当前处于绑定状态，unbind 为解绑状态
    wxbind_get_btn_show: true, //微信绑定发送验证码按钮状态
    wxbind_get_conut: 0, //微信绑定发送验证码倒计时
    wxbind_code: '', //微信绑定验证码
    wxbind_cod_timer: null, //定时器
    is_show_bindwx_qcode_box: false, //显示微信二维码
    is_show_forcebind_box: false, //显示强制绑定框
    

    fullPath: 'recently_opened',
    // 使用率
    usage: '0',
    total: '100M',
    percentage: 20,
    customColors:[
      { color:'#4383f0',percentage:90},
      { color:'red',percentage:91},
      { color:'red',percentage:100}
    ],
    neicun_man: false, //上传文件夹时内存是否满
    is_upload_dir_ing: false, //是否正在上传文件夹
    
    //可上传文件类型
    upload_file_types: ['.dwg', '.dxf', '.stp', '.step', '.stpz', '.igs', '.iges', '.ipt', '.iam', '.sldprt', '.sldasm', '.model', '.session', '.exp', '.catpart', '.catproduct', '.3dxml', '.cgr', '.prt', '.asm', '.par', '.asm', '.psm', '.prt', '.jt', '.j_t', '.stl', '.rvt', '.3dm', '.sat', '.asat', '.sab', '.asab', '.x_t', '.x_b', '.rfa', '.ifc'],

    //mask遮罩层
    mask: false,
    // pay支付
    is_show_pay: false,

    cloudfile_wait_pdfs: [], // 等待转换的PDF文件
    cloudfile_pdf_timer: null, //云盘文件列表转PDF定时器

    guide_action: false, //云盘页引导画面，默认关闭
    cloud_guanggao_action: false, //左侧群大图弹窗
    // 云盘，最近打开
    tableData_recently_open: [],
    tableData_loading_recently_open: true,
    tableData_recently_sort: {
      text: '日期',
      type: 'time'
    }, //最近列表排序

    //云盘， 我的图纸
    tableData_my_drawings: [],
    tableData_loading_my_drawings: true,
    is_show_create_dwg_box: false, //新建图纸
    tableData_my_drawings_sort: {
      text: '日期',
      type: 'time'
    }, //云图纸列表排序

    copy_cur_item_data: {
      name: ''
    },
    copy_cur_item_data_type: 'copy',
    menus_paste_action: false,
    is_show_cover_files_box: false, //覆盖文件弹出层

    // 上传记录
    is_show_up_history: false,
    is_show_up_all: false,
    waitFileList: [], //上传记录列表

    // 我的图纸 路径
    cur_cloud_paths: [{ text: "CAD云盘", path: "/" }],
    cur_parent_dir: '/', //我的图纸当前路径

    cur_checked_path: '/', //新建图纸默认路径
    //云盘， 我的收藏
    tableData_collection: [],
    tableData_loading_collection: true,
    tableData_collection_sort: {
      text: '日期',
      type: 'time'
    }, //收藏列表排序

    //云盘 移动文件
    is_show_move_dwg_box: false, //显示隐藏移动文件框
    cur_move_file_data: '',
    // 批量管理
    checkedItems: [], //批量勾选
    batchCheckedItems: [], //批量分享的勾选
    mShareCheckedItems: [], //接收分享界面的勾选

    // 我的群
    my_teams:[],
    my_teams_loading: true,
    cur_checke_tid: '', //当前选中群id
    get_team_data: null, // 当前群信息
    is_show_add_team: false,  //显示新建群弹窗
    is_show_member: false, //显示群成员管理弹窗
    is_show_team_setting: false, //显示群设置弹窗
    is_show_jion_team_qcode: false, //显示加入群的二维码
    is_show_team_add_cloudfile: false, //显示添加云盘文件
    // 我的群文件
    my_team_files:[],
    my_team_files_loading: true,

    //云盘分享图纸
    is_show_share_box: false,
    is_show_share_box2: false,
    cur_show_action: 'url',
    share_from: 'mycloud',
    cur_share_data: {
      name:'',
    },
    share_box_url:'正在努力获取中...',
    share_is_tag: false,
    cur_share_tag_pos_id: '',

    // 批量分享
    show_batch_share: false,

    //dwg页面
    dwg_setting: {},
    if_setting_from: 0, // 0 来自设置按钮， 1 来自 在屏幕上指定
    is_show_setting: false, //显示隐藏设置框
    form_setting_bg: false, //是否来自设置框设置
    dwg_user_settings_cache: { // 用户设置 - 初始配置
      operation_log: true, // 记录开关
      jizhou: {
          open: true,
          value: 90
      },
      backgroundColor: '212830', // 背景色
      catch_sign_color: [255, 127, 0], // 捕捉标记颜色
      guide_color: [0, 165, 0], // 辅助线颜色
      biaozhu_color: [255, 127, 0], // 标注颜色
      biaozhu_text_color: [0, 165, 0], // 标注文字颜色
      measure_is_save_dwg: 1, // 测量数据是否保存在图纸 ,1 保存 0 不保存
      biaozhu_jingdu: "0", // 精度测量 标注精度
      biaozhu_bili: "1:1(毫米)", // 精度测量 标注比例
      measure_area_unit: 0, // 测量面积 单位
    },

    dwg_user_settings_default: {
      operation_log: true, // 记录开关
      jizhou: {
        open: true,
        value: 90
      },
      backgroundColor: '212830', // 背景色
      catch_sign_color: [255, 127, 0], // 捕捉标记颜色
      guide_color: [0, 165, 0], // 辅助线颜色
      biaozhu_color: [255, 127, 0], // 标注颜色
      biaozhu_text_color: [0, 165, 0], // 标注文字颜色
      measure_is_save_dwg: 1, // 测量数据是否保存在图纸 ,1 保存 0 不保存
      biaozhu_jingdu: "0", // 精度测量 标注精度
      biaozhu_bili: "1:1(毫米)", // 精度测量 标注比例
      measure_area_unit: 0, // 测量面积 单位
    },  //用户默认配置

    operation_log: ['1111111111111'], //操作记录
    is_show_operation_log: false, //操作记录显示隐藏
    
    is_show_bak: false, // 是否打开备份文件
    feedbackVisible: false, //意见反馈
    need_check_save: false, //需要保存
    need_save_dwg: false, // 图纸修改需要保存弹框 
    save_box_from: '', //来自哪个按钮
    is_show_share_save_cloud_box: false, //分享图纸保存弹出选择路径
    is_show_save_cloud_box: false, //批量分享保存至云盘弹窗
    viewer: null,
    cur_action: '', //当前绘图命令
    dwg_input_word: '', //命令提示文本
    last_words: [], //历史记录列表
    animate_last_words: false, //历史记录列表显示隐藏

    is_in_quick_cmd_input: false, // 是否在快捷命令输入中
    quick_cmd_grep: '', // 当前快捷命令匹配
    cur_cmd_has_btns: [], // 当前命令的快捷按钮
    can_input_quick_cmd_cmd: false, // 是否有命令中的快捷按钮 相当于cur_cmd_has_btns.length
    is_in_quick_cmd_cmd_input: false,

    Currentlayer: '', //当前图层
    Currentlayer_COLOR: '#fff', //当前图层颜色
    Currentlayer_OPEN_ACTION: '', //当前图层开关
    Currentlayer_SUO_ACTION: '', //当前图层锁
    Currentlayer_DJ_ACTION: '', //当前图层冻结/解冻
    layers: [], //图层

    cur_checke_layer: null,
    is_show_layerColorBox: false, //图层颜色框显示与隐藏
    layerColorTop: 100, //图层颜色框top
    layerColorRight: 105, //图层颜色框Right

    is_show_print_box: false, //显示隐藏打印框
    fillcolorbox: false, //颜色填充框

    cur_dwg_tuku: 'curdwg',
    // 当前图纸
    curdwg_blocks: [], // 上次获取当前图纸图块xinxi
    curdwg_cur_dwg: null, // 当前图纸 当前选中 图块
    curdwg_block_load_part_finish: [], // 已加载缩略图 part
    curdwg_block_load_part: 1, // 缩略图 part
    curdwg_block_img_name_url: {}, // 已获取过的缩略图
    need_render: [], //需要追加
    is_first: true,
    last_curdwg_blocks: [],
    // CAD云图库
    gallery_cur_deep: 'type',
    gallery_cur_type: '',
    gallery_cur_gallery: { 'id': 0, 'name': '' },
    gallery_cur_second: '',
    gallery_cur_dwg: null, // 图库 当前选中 图块
    gallery_cur_dwg_is_gv: 0, // 当前选中的图块是否为精品图块
    gallery_last_types: {}, // 上次获取的图库分类信息 // { gallery_types: [gallerys] }
    gallery_last_gallerys: {}, // 上次获取的图库信息 // { gallery_ids: {classify_seconds: [dwgs]} }
    gallery_cur_sort: 'time', // 图库图块 排序规则
    // 收藏
    collect_cur_favorites_id: 0, // 当前收藏夹id 全部为0
    collect_cur_favorites_collect_id: 0, // 当前收藏时的收藏夹id
    collect_cur_favorites_name: '全部收藏',
    collect_cur_favorites_collect_name: '', // 当前收藏时的收藏夹名称
    collect_cur_dwg: null, // 收藏 当前选中 图块
    collect_last_favorites: [], // 上次获取的收藏夹信息 // []
    collect_cur_sort: 'time', // 收藏图块 排序规则
    collect_dwgs: [], //收藏全部图纸
    // 最近使用  // 图块来自 当前图纸/收藏/图库
    recent_is_get: false, // 是否已获取 图块使用的 云端记录
    recent_use_dwg: [], // 最近使用的图块 最多100个 // ['1','10000001','curdwg_{图块名称}']
    recent_cur_dwg: null, // 图库 当前选中 图块
    recent_last_dwgs: [], // 最近使用 上次获取的图块信息
    recent_curdwg_dwgs: [], //最近图块列表
    // 搜索
    tuku_is_in_search: false,
    is_in_tuku_input: false,
    tuku_search_input_text: '',

    //特性
    is_need_get_property: false,  // 是否需要获取属性
    is_in_property_input: false,
    // 阵列弹窗显示 || 隐藏
    // 0 : KARRAYCOL  列数
    // 1 ：KARRAYVER  行数
    // 2 ：KARRAYCOLDIS 列距
    // 3 ：KARRAYVERDIS 行距
    // 4 ：KARRAYTAG   手动输入  行数
    // 5 : KARRAYTAG2      手动输入 列数
    // 6 : KARRAYCIRCLENUM   环形阵列个数
    // 7 : KARRAYCIRCLEROTE  开启旋转  0 关 1 开
    // 8 : KARRAYSPACING      路径阵列  间距
    array_status: false,
    rectParams:{ a:"5", b:"6",c:"100000",d:"1000000",e:"0",f:"0",g:"1",h:"0",j:"10000"},
    

    show_del_type: false, //删除方式 框
    show_pldels_type: true, //批量删除框
    pldelsObj: null, //批量框数据
    is_show_wtext: false, //单行文本 多行文本显示
    is_wtexts: true, //false单行文本， true 多行文本

    show_extract_text: false, //提取文字 
    show_extracted_txt: false, //提取的文字
    extracted_txt: '', //提取到的文字
    is_show_BiaozhuSet: false, // 标注设置框
    is_show_BiaogaoSet: false, // 标高设置框
    is_show_MergeBlock: false, //合并图块框
    is_show_InputBlockName: false, //输入块名框
    show_insert_table: false, //插入表格
    show_insert_table2: false, //插入表格2
    show_mate_layer: false, //匹配图层框
    matelayers: [], //匹配图层列表
    mate_layer_index: 0,
    is_show_polygon: false, //正多边形框
    show_block_box: false, //图块列表框
    blocks:[], // 图块列表
    block_index:0,
    insertTable: { Tablecol: '4', Tablerow: '4', Tablehig:'700', Tablewid: '4000', Tablename: ''},
    is_show_calculator: false, //计算器
    // 云线
    cloud_line_status: false,

    // 图案填充
    pattern_fill: false,
    chose_layer_drop: {
      name: '0',
      color: '#fff',
      dj_action: false,
      kg_action: false,
      suo_action: false
    }, //菜单栏图层显示
    //特性匹配
    chose_color: {text: 'ByLayer',rgb: '255,255,255'},          // 选中的颜色
    cast_line_type_chose: { text: '跟随图层', active: '0', value: "ByLayer" }, // 线型下拉框选中的数据
    cast_xk_chose: { id: 3, text: "默认", active: '1', value: "kLnWtByLwDefault", int: -3 }, // 特性线宽选中的数据

    cur_key_is_down: { ctrl: 0, shift: 0, middle: 0 }, // ctrl / shift / 鼠标中键 是否被按下

    depot_edit: {
      state: false,
      rotate_num: 1,
      is_change: false
    }, // 图块编辑
    // 图库更新提示
    gallery_nav_info: {},

    // 正多边形设置
    set_polygon: {
      num: 3,
      type: "2",
    },

    cur_click_btn_type: '',// 当前点击的按钮弹框类型，显示 优化弹框不消失的问题
    convertpdf_box: false, // 转换PDF

    // CDF页面
    is_show_cdf_subs: false, // 还有子文件未上传或转换框
    cdf_subs: [], // 未上传转换的文件
    is_show_dwg_contrast: false, //图纸对比框显示
  },

  //属性二：getters相当于vue中的computed属性（state中的数据不是我们想要展示的数据-->对数据在进行处理）  在.vue文件中使用通过this.$store.getters.定义方法的名称去获取数据  也可以使用辅助函数MapGetters获取数据  具有缓存机制
  getters: {

  },
  //属性三:mutations 用来修改state中的数据   注意:只能使用同步的写法
  mutations: {
    //我的图纸
    updateMyTableData(state, data) {
      var items = JSON.parse(JSON.stringify(data))
      state.tableData_my_drawings = items
    },

    //最近列表
    updateRecentlyData(state, data) {
      var items = JSON.parse(JSON.stringify(data))
      state.tableData_recently_open = items
    },

    // 收藏列表
    updateCollectionData(state, data) {
      var items = JSON.parse(JSON.stringify(data))
      state.tableData_collection = items
    },

    // 最近打开 修改分享图纸的当前信息
    updateCurShareData_recently (state, data) {
      var item = JSON.parse(JSON.stringify(data))
      state.cur_share_data = item
    },
    // 我的图纸 修改分享图纸的当前信息
    updateCurShareData_cloud (state, data) {
      var item = JSON.parse(JSON.stringify(data))
      state.cur_share_data = item
    },

    // dwg 修改分享图纸的当前信息
    updateCurShareData_dwg (state, data) {
      var item = JSON.parse(JSON.stringify(data))
      state.cur_share_data = item
    },

    //修改上传记录
    updateWaitFileList (state, data) {
      var item = JSON.parse(JSON.stringify(data))
      state.waitFileList = item
    },

    uploadFullPath (state, data) {
      var value = JSON.parse(JSON.stringify(data))
      state.fullPath = value
    },

    // 我的群 文件列表
    updateMyTeamFiles (state, data) {
      var items = JSON.parse(JSON.stringify(data))
      state.my_team_files = items
    },

    updateMylayers (state, data) {
      var items = JSON.parse(JSON.stringify(data))
      state.layers = items
    },

    // 阵列弹窗显示 || 隐藏
    updateArrayStatus (state, data) {
      state.array_status = data
    },

    //修改阵列相关属性
    updateRectParams (state, data) {
      state.rectParams = data
    }, 

    // 云线显示 || 隐藏
    updateCloudLineStatus (state, data) {
      state.cloud_line_status = data
    },

    // 图案填充显示 || 隐藏
    updatePatternFill (state, data) {
      state.pattern_fill = data
    },
    // 所有点击按钮的弹窗
    updateCurClickBtnType(state, data) {
      state.cur_click_btn_type = data
    },
    // 图块编辑显示 || 隐藏, 旋转次数
    updateEditDepot (state, data) {
      if (typeof data == 'boolean') {
        state.depot_edit.state = data;
      }
    },
    // 图块的长宽
    updateDepotLongWidth (state, data) {
      state.depot_edit.is_change = data;
    },

    // 图库点击更新提示中的查看
    updateGalleryNavInfo (state, data) {
      state.gallery_nav_info = data;
    },
  },
  //属性四：actions 用来处理异步  返回的是mutations并不是具体的值
  actions: {

  },
  modules: {

  }
})
