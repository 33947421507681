<template>
  <div class="container" v-loading="is_loding">
    <div class="nav">
      <div class="logo-box" @click="go_to_home">
        <div class="logo-img">
          <img src="@/assets/images/common/logo.png" />
        </div>
        <div class="logo-word nav-logo-text">{{ headerTitle }}</div>
      </div>
      <div class="rt_box">
        <div class="login_btn" v-if="!userinfo" @click="(is_show_login = true)">立即登录</div>
        <div class="others" v-else>
          <el-dropdown>
            <div class="avatar-wrap">
              <img id="avatar" :src="userinfo.is_wx == 0 ? avatar : userinfo.wx_avatar" />
              <div id="user_account" style="display: block" v-if="userinfo.is_wx == 0">
                {{ userinfo.user_name == "" || userinfo.user_name == null ? userinfo.account : userinfo.user_name }}
              </div>
              <div id="user_account" style="display: block" v-if="userinfo.is_wx == 1">
                {{ userinfo.wx_nickname }}
              </div>
              <div class="vip_avatar" v-show="userinfo.is_vip == 1 || userinfo.is_qy_vip == 1">
                <img
                  id="vip_avatar"
                  src="@/assets/images/common/name_vip.png"
                />
              </div>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div class="user-card">
                <div class="uc-top">
                  <div class="uct-avatar">
                    <img
                      id="uc_avatar"
                      :src="userinfo.is_wx == 0 ? avatar : userinfo.wx_avatar"
                    />
                  </div>
                  <div class="uct-info">
                    <div id="user_nickname">
                      <span v-if="userinfo.is_wx == 0">{{
                        userinfo.user_name == "" || userinfo.user_name == null
                          ? userinfo.account
                          : userinfo.user_name
                      }}</span>
                      <span v-if="userinfo.is_wx == 1">{{ userinfo.wx_nickname }}</span>
                      <img
                        id="vip_avatar_biaozhi"
                        src="@/assets/images/common/name_vip.png"
                        v-if="userinfo.is_vip == 1 || userinfo.is_qy_vip == 1"
                      />
                    </div>
                    <div id="vip_endtime">到期时间：{{ userinfo.vip_endtime }}</div>
                    <div id="vip_adword">{{ vip_title }}</div>
                  </div>
                  <div id="immediately_buy_vip" @click="is_show_pay_btn">
                    {{ by_vip_btn }}
                  </div>
                </div>
                <div class="uc-cloud-progress">
                  <div class="uc-cloud-size-word">
                    <div class="uc-csw-l">
                      <span class="cloud-usage">{{ $store.state.usage }}</span
                      >/<span class="cloud-total">{{
                        $store.state.total
                      }}</span>
                    </div>
                  </div>
                  <el-progress
                    :show-text="false"
                    :percentage="$store.state.percentage"
                    :color="$store.state.customColors"
                  ></el-progress>
                </div>
                <div>
                  <router-link to="/usercenter" id="to_userCenter"
                    >个人中心</router-link
                  >
                  <div id="login_out" @click="onLogout">退出登录</div>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="buy" @click="is_show_pay_btn">{{ by_vip_btn }}</div>
        </div>
      </div>
    </div>
    <div class="line1"></div>
    <div class="wrapper1">
      <div class="icon"><img src="@/assets/images/share_page/share_pack.png" alt=""></div>
      <div class="info_box">
        <div class="name_box">
          <span class="name" v-if="files.length > 0">{{ files[0].name }}</span>
          <span v-if="files.length > 1">等{{ files.length }}个文件</span>
        </div>
        <div class="others">
          <span class="from_name">{{ share_from_name }}</span>
          &nbsp;|&nbsp;
          <span>{{ share_from_time }}</span>
        </div>
      </div>
      <div class="cloud-btns" v-if="!is_guoqi">
        <el-button
          plain
          class="my_dwg_send_all"
          @click="sendAllFiles"
          >发送所有图纸</el-button
        >
        <el-button
          plain
          class="my_dwg_top_btn"
          @click="DOWN_FILES('downs')"
          :disabled="share_type == 'down' && checkedItems.length  == 0"
          v-if="share_type == 'down'"
          >下载图纸</el-button
        >
        <el-button
          plain
          class="my_dwg_top_btn save_to_cloud"
          style="width: 120px"
          @click="DOWN_FILES('saveclouds')"
          :disabled="checkedItems.length  == 0"
          >保存到云盘</el-button
        >
      </div>
    </div>

    <div class="wrapper2" v-if="!is_guoqi">
      <div class="cloud_file_header lately_file_header">
        <div class="cloud_file_header_checked_box">
          <el-checkbox
            style="height: 19.69px"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >{{ checkAllText }}</el-checkbox
          >
        </div>
      </div>

      <div class="share_lists_box">
        <div class="share_lists_item">
          <div class="share_lists_item_title share_lists_item_title1">名称</div>
          <div class="share_lists_item_title share_lists_item_title2">
            文件类型
          </div>
          <div class="share_lists_item_title share_lists_item_title3">
            文件大小
          </div>
          <div class="share_lists_item_title share_lists_item_title4">
            操作
          </div>
        </div>
        <div id="share_lists_items">
          <div class="file-item" v-for="(item, index) in files" :key="index">
            <el-checkbox
              @change="handleCheckedMyTableChange(item)"
              v-model="item.checked"
              v-if="!item.input"
            ></el-checkbox>
            <div class="file_info_box">
              <div class="file-name">
                <img src="@/assets/images/cloud/file_cloud.png" />
                <div
                  class="file-name-word"
                  :title="item.name"
                  @click="mshare_open_dwg(item)"
                >
                  {{ item.name }}
                </div>
              </div>
              <!-- <div class="file-update-time">{{ item.mtime }}</div> -->
              <div class="file-type">DWG文件</div>
              <div class="file-size">{{ item.size }}</div>
              <div class="file-operate">
                <div class="file-item-btns-box">
                  <div class="file-item-btns">
                    <div
                      class="file-item-btn file-item-save"
                      title="保存到云盘"
                      @click="mshare_save_cloud(item)"
                    >
                    </div>
                    <div
                      class="file-item-btn file-item-down"
                      title="下载到本地"
                      v-if="share_type == 'down'"
                      @click="mshare_down(item)"
                    >
                      <!-- <img src="@/assets/images/share_page/down_item.png" /> -->
                    </div>
                    <div
                      class="file-item-btn file-item-open"
                      title="打开"
                      @click="mshare_open_dwg(item)"
                    >
                      <!-- <img src="@/assets/images/share_page/open_no.png" /> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="shixiao_box" v-if="is_guoqi">
      <div class="shixiao_center">
        <div class="shixiao_center_img">
          <img src="@/assets/images/share_page/share_shixiao.png" alt="" />
        </div>
        <div class="shixiao_center_title1">链接/二维码已失效</div>
        <div class="shixiao_center_title2">请联系好友重新获取链接后访问</div>
      </div>
    </div>


    <SaveCloudView></SaveCloudView>
    <Login
      :cur_show_box="cur_show_box"
      :is_show_login="is_show_login"
      v-if="is_show_login"
      @change_child_value="change_child_value"
    ></Login>
    <PayView v-if="$store.state.is_show_pay" :sendUserInfo="userinfo"></PayView>
    <MaskLayer v-show="$store.state.mask"></MaskLayer>
    <ShareView2 :sendInfo="{ share_type: share_type, share_time: share_time  }"></ShareView2>
  </div>
</template>


<script>
import { get_mshare_files } from "@/api/share";
import SaveCloudView from './save_cloud.vue'
import Login from "@/components/login";
import PayView from "@/components/pay.vue";
import MaskLayer from "@/components/mask.vue";
import ShareView2 from "@/components/share_box2.vue";
import { login_check, usertoken_login, getUsage, login_out } from "@/api/user";

import utils from "@/utils/utils";
import Common from "@/utils/common";
export default {
  name: "MsharePage",
  components: {
    SaveCloudView,
    Login,
    PayView,
    MaskLayer,
    ShareView2
  },
  data() {
    return {
      headerTitle: Common.headerTitle,
      is_show_login: false,
      cur_show_box: "login",
      userinfo: null,
      share_from_name: "",
      share_from_time: "",
      files: [],
      share_uid: "",
      av: require("@/assets/images/common/avatar.png"),
      time: require("@/assets/images/share_page/time.png"),
      gqAv: require("@/assets/images/share_page/guoqi_av.png"),
      gqTime: require("@/assets/images/share_page/guoqi_time.png"),
      is_guoqi: false,
      is_loding: true,
      share_type: "see",

      checkAll: false, // 全选
      is_show_checkedAll: true, //是否显示全选
      isIndeterminate: true,
      checkAllText: "全选", //已选中多少条
      checkedItems: [],
      menus_down_action: true,
      menus_save_action: true,

      timerArr: [],
      avatar: require("@/assets/images/avatar.png"),

      vip_title: '',
      by_vip_btn: '',
      share_time: '',
    };
  },

  mounted() {
    var that = this;
    var send = {
      mshare_id: utils.getQueryVariable("share_id"),
    };

    get_mshare_files(send).then((res) => {
      var data = JSON.parse(res.data);
      that.is_loding = false;
      if (data.code == 1) {
        // 没过期
        that.share_from_name = "来自「" + data.datas.share_account + "」的分享";
        if (data.datas.share_remain_time > 315360000) {
          that.share_from_time = "剩余时间：永久";
        } else {
          that.share_from_time =
          "剩余时间：" + utils.formatSeconds(data.datas.share_remain_time);
        }
        var items = data.datas.files;

        items.forEach((item) => {
          item.size = utils.convert_size_MB(item.size);
          item.checked = false
          // item.mtime = utils.convert_date_day(item.mtime)
        });
        that.files = items;
        that.share_uid = data.datas.share_uid;
        that.share_type = data.datas.share_type;

        that.share_time = data.datas.share_remain_time;
      } else if (data.code == 2) {
        // 过期

        that.is_guoqi = true;
        that.share_from_name = "来自「" + data.datas.share_account + "」的分享";
        that.share_from_time = "剩余时间：" + "已过期";
      } else if (data.code == 4) {
        // 不存在
        that.is_guoqi = true;
        that.share_from_name = "该分享链接不存在";
        that.share_from_time = "过期时间：-";
      }
    });
  
  
    var usertoken = encodeURIComponent(utils.getQueryVariable('usertoken'))
    if (usertoken == '') {
      this.loginCheck()
    } else {
      this.auto_usertoken_login(usertoken, 'token')
    }

    this.$EventBus.$off("reflushUserinfoIndex");
    this.$EventBus.$on("reflushUserinfoIndex", function () {
      // 开始调用方法
      this.loginCheck()
    });
  },

  methods: {
    go_to_home() {
      this.$router.push("/my_drawings");
    },

    loginCheck() {
      var that = this
      login_check().then(res=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          that.userinfo = data.data
          localStorage.setItem("USERTOKEN", JSON.stringify(data.data.user_token))
          localStorage.setItem("USER_INFO", JSON.stringify(data.data));
        
          if (that.userinfo.is_qy_vip == "1" || that.userinfo.is_vip == "1") {
            that.vip_title = "您已享受所有会员功能";
            that.by_vip_btn = "立即续费";
          } else {
            that.vip_title = "购买服务享受所有功能";
            that.by_vip_btn = "升级账户";
          }

          that.get_Usage(that.userinfo);
        } else {
          that.userinfo = null
          // that.$msg.whiteerr('登录信息已过期，请重新登陆')
          // utils.del_localStorae()
          that.auto_login()
        }
      })
    },

    get_Usage(user_info) {
      getUsage().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.auto_login();
        } else {
          this.$store.state.userinfo = user_info;
          this.$store.state.usage = utils.convert_size_MB(data.usage);
          this.$store.state.total = utils.convert_size_MB(data.total);
          this.$store.state.percentage =
            ((data.usage / data.total) * 100).toFixed(0) - 0;
        }
      });
    },

    // 自动登录
    auto_login() {
      var USER_INFO_TIME = parseInt(localStorage.getItem("USER_INFO_TIME"));
      var cur_time = new Date().getTime();
      if (
        cur_time < 7 * 86400 * 1000 + USER_INFO_TIME ||
        USER_INFO_TIME == null
      ) {
        // 7天内登录过
        var USER_TOKEN = JSON.parse(localStorage.getItem("USERTOKEN"));
        if (USER_TOKEN != null) {
          //token存在
          this.auto_usertoken_login(USER_TOKEN, 'auto');
        } else {
          //token不存在
         utils.del_localStorae();
         this.userinfo = null
        }
      } else {
        utils.del_localStorae();
        this.userinfo = null
      }
    },

    auto_usertoken_login(user_token, from) {
      var that = this
      var send = {
        user_token: user_token,
      };
      usertoken_login(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var __userinfor = data.user_info;
          that.userinfo = data.user_info
          localStorage.setItem("USER_INFO_TIME", new Date().getTime());
          localStorage.setItem("USER_INFO", JSON.stringify(__userinfor));
          localStorage.setItem("USERTOKEN", JSON.stringify(__userinfor.user_token));
          that.$store.state.userinfo = __userinfor;

          if (that.userinfo.is_qy_vip == "1" || that.userinfo.is_vip == "1") {
            that.vip_title = "您已享受所有会员功能";
            that.by_vip_btn = "立即续费";
          } else {
            that.vip_title = "购买服务享受所有功能";
            that.by_vip_btn = "升级账户";
          }

          that.get_Usage(that.userinfo);
        } else {
          that.$msg.whiteerr('登录信息已过期，请重新登陆')
          utils.del_localStorae();
          that.userinfo = null
        }
      });
    },

    change_child_value(data) {
      this.is_show_login = data;
    },

    mshare_open_dwg(item) {
      if (item.file_type == "3d") {
        let pathInfo = this.$router.resolve({
          path: "/cdf",
          query: {
            share_id: item.share_id,
          },
        });
        window.open(pathInfo.href, "_blank");
      } else {
        let pathInfo = this.$router.resolve({
          path: "/dwg",
          query: {
            share_id: item.share_id,
          },
        });
        window.open(pathInfo.href, "_blank");
      }
    },

    //全选
    handleCheckAllChange() {
      if (this.isIndeterminate) {
        this.isIndeterminate = false;
        var items = this.files;
        this.checkedItems = [];
        items.forEach((item) => {
          item.checked = true;
          this.checkedItems.push(item);
        });
        this.checkAll = true;
        this.checkAllText = "已选" + items.length + "项";
      } else {
        var items1 = this.files;
        items1.forEach((item) => {
          item.checked = false;
        });
        this.clear_checked_action();
      }
    },

    handleCheckedMyTableChange(item) {
      var items = this.files;

      // 选中数组 $store.state.checkedItems
      //true时 加入， false 减少

      if (item.checked) {
        this.is_show_checkedAll = true;
        this.push_checked_items(item);
      } else {
        this.is_show_checkedAll = true;
        this.del_checked_items(item.name);
      }

      //检测全选按钮状态 是否为全选
      if (items.length == this.files.length) {
        // 全选
        this.checkAll = true;
        this.inputPattern = false;
        this.checkAllText =
          "已选" + this.checkedItems.length + "项";
      } else {
        // 未全选
        this.checkAll = false;
        this.inputPattern = true;
        this.checkAllText =
          "已选" + this.checkedItems.length + "项";
      }

      if (this.checkedItems.length == 0) {
        this.checkAll = false;
        this.inputPattern = false;
        this.is_show_checkedAll = false;
        this.checkAllText = "全选";
      }
    },

    // 添加、选中项
    push_checked_items(item) {
      this.checkedItems.push(item);
    },
    // 移除、选中项
    del_checked_items(name) {
      var new_checked_items = [];
      if (this.checkedItems.length == 1) {
        this.checkedItems = [];
        return;
      }
      this.checkedItems.forEach((item) => {
        if (item.name != name) {
          new_checked_items.push(item);
        }
      });
      this.checkedItems = new_checked_items;
    },

    // 无任何选择清除状态
    clear_checked_action() {
      this.checkedItems = [];
      this.checkAll = false;
      this.is_show_checkedAll = false;
      this.isIndeterminate = true;
      this.checkAllText = "全选";
      this.menus_save_action = true;
      this.menus_down_action = true;
    },

    // 保存至云盘
    mshare_save_cloud(item) {
      if (this.userinfo == null) { 
        this.is_show_login = true;
      } else {
        if (this.share_uid == this.userinfo.uid) {
          this.$msg.whiteerr('以下是您的的文件，无需此操作！')
          return
        }
        this.$store.state.is_show_save_cloud_box = true
        let items = []
        items.push(item)
        this.$EventBus.$emit("saveCloudGetDirLists" , JSON.stringify(items))
      }
      
    },
    // 下载到本地
    async mshare_down(item) {
      var share_type = this.share_type
      if (share_type == 'down') {
       await this.downFile(item.share_id)
      }
    },

     // 批量管理 - 保存到云盘 下载
    async DOWN_FILES(type) {
      var items = this.checkedItems;
      if (type == 'saveclouds') {
        if (this.userinfo == null) { 
          this.is_show_login = true;
        } else {
          if (this.share_uid == this.userinfo.uid) {
            this.$msg.whiteerr('以下是您的的文件，无需此操作！')
            return
          }
          this.$store.state.is_show_save_cloud_box = true
          this.$EventBus.$emit("saveCloudGetDirLists" , JSON.stringify(items))
        }
      } else if (type == 'downs') {
        for (const key in items) {
          await this.pause();
          await this.downFile(items[key].share_id);
        }
      }
     
    },

    // 暂停1s
    pause() {
      return new Promise((resolve) => {
        let timer = setTimeout(resolve, 1000);
        this.timerArr.push(timer);  // 创建了定时器，放到一个数组中，最后将其清除掉。
      });
    },

    async downFile (share_id) {
      if (this.isIE()) { // IE
        window.open('https://oc.wkcad.com' + '/cloud/share/download_dwg?share_id=' + share_id, '_blank')
      } else {
        console.log(share_id)
        let a = document.createElement('a') // 创建a标签
        let e = document.createEvent('MouseEvents') // 创建鼠标事件对象
        e.initEvent('click', false, false) // 初始化事件对象
        a.href = 'https://oc.wkcad.com' + '/cloud/share/download_dwg?share_id=' + share_id // 设置下载地址
        a.download = '' // 设置下载文件名
        a.dispatchEvent(e)
      }      
    },

    isIE() {
      if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        return true
      } else {
        return false
      }
    },

    is_show_pay_btn() {
      this.$store.state.is_show_pay = true;
      this.$store.state.mask = true;
    },

    sendAllFiles() {
      var items = this.files;
      this.$store.state.mShareCheckedItems = items;

      this.$store.state.share_from = 'myclouds'
      this.$store.state.is_show_share_box2 = true;

      this.$store.state.cur_share_data = items[0];
      this.$store.commit("updateCurShareData_cloud", this.$store.state.cur_share_data);
      var type = this.$store.state.cur_show_action

      this.$EventBus.$emit("showShareBox2", 'mshare');
    },

    onLogout() {
      this.$confirm("确认退出吗？", "退出提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.login_out_funtion();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    login_out_funtion() {
      login_out().then(() => {
        utils.del_localStorae();
        
        sessionStorage.clear()

        window.location.reload();
      });
    },
  },
  destroyed() {},
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.nav {
  width: 100%;
  min-width: 1260px;
  height: 56px;
  background-color: #fff;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rt_box {
    margin-right: 20px;

    .login_btn {
      width: 116px;
      border-radius: 4px;
      padding: 11px 0 10px;
      font-size: 14px;
      color: #FFFFFF;
      text-align: center;
      line-height: 21px;
      background: #2072EC;
      cursor: pointer;
    }

    .others {
      display: flex;
      align-items: center;

      .avatar {
        width: 30px;
        height: 30px;
        display: none;
        padding: 13px 0;
      }

      .avatar img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      #user_account {
        height: 56px;
        line-height: 56px;
        display: none;
        margin: 0 5px;
        color: #333;
        font-size: 14px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .vip_avatar {
        margin: 0 5px 0 5px;
        display: flex;
      }

      #vip_avatar {
        width: 16px;
        height: 16px;
      }

      .buy {
        width: 102px;
        padding: 11px 0 10px;
        margin-left: 15px;
        margin-right: 13px;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        line-height: 21px;
        background: #2072EC;
        cursor: pointer;
      }
    }
  }
}

.avatar-wrap {
  display: flex;
  align-items: center;
}

.user-card {
  width: 316px;
  height: 236px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(137, 136, 143, 0.16),
    0px 1px 11px 0px rgba(115, 115, 115, 0.15);
  border: 1px solid #e7e7e7;
  position: absolute;
  top: -15px;
  right: -50px;
}

.uc-top {
  width: 296px;
  height: 102px;
  margin-top: 10px;
  margin-left: 11px;
  border-radius: 4px;
  background-image: url("@/assets/images/user_info_bg.png");
  position: relative;
}

.uct-avatar {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 21px;
  left: 11px;
}

.uct-avatar img {
  width: 100%;
  border-radius: 100%;
}

#user_nickname {
  position: absolute;
  top: 18px;
  left: 57px;
  font-size: 14px;
  color: #fff;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#user_nickname span {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

#vip_endtime {
  position: absolute;
  top: 41px;
  left: 57px;
  font-size: 12px;
  color: #fff;
}

#vip_adword {
  position: absolute;
  top: 65px;
  left: 57px;
  font-size: 12px;
  color: #fff;
}

#vip_avatar,
#vip_avatar_biaozhi {
  width: 16px;
  height: 16px;
}

#vip_avatar_biaozhi {
  margin: 0 5px;
}

#immediately_buy_vip {
  position: absolute;
  top: 22px;
  right: 13px;
  width: 77px;
  height: 27px;
  background-image: linear-gradient(122deg, #f3d6a3 0%, #f8e9bf 100%);
  border-radius: 6px;
  font-size: 14px;
  color: #765d43;
  font-weight: bold;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
}

.uc-cloud-progress {
  margin-left: 11px;
  margin-top: 20px;
}

.uc-cloud-size-word {
  width: 296px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  color: #666;
}

.uc-csw-l {
  float: left;
}

#to_userCenter {
  width: 64px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #666;
  position: absolute;
  left: 9px;
  bottom: 20px;
  cursor: pointer;
}

#login_out {
  width: 64px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #666;
  position: absolute;
  right: 9px;
  bottom: 20px;
  cursor: pointer;
}

#to_userCenter:hover,
#login_out:hover {
  color: #4383f0;
}

.logo-box {
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.logo-img {
  display: flex;
  align-items: center;
  width: 48px;
  margin-left: 16px;
}

.logo-word {
  font-size: 20px;
  color: #333;
  font-weight: bold;
  margin-left: 8px;
}

.logo-img img {
  width: 100%;
}

::-webkit-scrollbar {
  /* 滚动条整体部分 */
  width: 5px;
  margin-right: 2px;
}

::-webkit-scrollbar-button {
  /* 滚动条两端的按钮 */
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
  margin-bottom: 2px;
}

::-webkit-scrollbar-track {
  /* 外层轨道 */
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece {
  /*内层轨道，滚动条中间部分 */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  /* 滑块 */
  width: 5px;
  border-radius: 5px;
  background: #cbcbcb;
}

::-webkit-scrollbar-corner {
  /* 边角 */
  width: 5px;
}

::-webkit-scrollbar-thumb:hover {
  /* 鼠标移入滑块 */
  background: #cbcbcb;
}

.line1 {
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
}

.wrapper1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 0 31px;
  background: #F9FAFB;

  .icon {
    padding: 21px 17px;
    border-radius: 4px;
    border: 1px solid #EAEBEB;
    margin-right: 10px;

    img {
      display: block;
      width: 18px;
      height: 18px;
    }
  }

  .info_box {

    .name_box {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: 600;
      color: #313131;
      line-height: 27px;

      .name {
        max-width: 150px;
        margin-right: 11px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .others {
      display: flex;
      align-items: baseline;
      font-size: 12px;
      color: #666666;
      line-height: 18px;
    }
  }
}

.wrapper2 {
  flex: 1;
  width: 742px;
  max-height: 690px;
  margin: 20px auto;
  padding: 0 31px 0 17px;
  background: #FFFFFF;
  box-shadow: 0px 3px 10px 1px rgba(117,123,130,0.19);
  overflow: hidden;
  box-sizing: border-box;
}

.guoqi_box {
  width: 100%;
  height: 48px;
  background-color: #f0f4fc;
  display: flex;
}

.guoqi_left {
  display: flex;
  align-items: center;
  margin-left: 35px;
  margin-right: 50px;
}

.guoqi_right {
  display: flex;
  align-items: center;
}

.guoqi_left img,
.guoqi_right img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.guoqi_left span,
.guoqi_right span {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #4383f0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.guoqi_box_gq {
  background: #e7e7e7;
}

.checked_all_box {
  height: 40px;
  min-width: 124px !important;
  margin-left: 35px;
  display: flex;
  align-items: center;
}

#checked_all_btn #checkbox_all_input {
  width: 14px;
  height: 14px;
  border-radius: 1px;
  border: solid 1px #ced0d1;
}

#checked_all_text {
  height: 19px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #4383f0;
  margin-left: 8px;
}

.share_lists_box {
  width: 100%;
  margin-top: 10px;
}

.share_lists_item {
  display: flex;
  align-items: center;
  height: 54px;
}

.share_lists_item_title {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  text-align: center;
}

.share_lists_item_title1 {
  margin-left: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  text-align: left;
}

.share_lists_item_title2,
.share_lists_item_title3 {
  width: 124px;
  text-align: center;
}

.share_lists_item_title4 {
  flex: 1;
}

// .file-update-time {
//   width: 124px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   font-family: PingFangSC-Regular;
//   font-size: 14px;
//   font-weight: normal;
//   font-stretch: normal;
//   letter-spacing: 0px;
//   color: #666;
// }

.file-type,
.file-size {
  width: 124px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #666;
}

.file_info_box {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.file-item {
  display: flex;
  align-items: center;
  height: 54px;
}

.file-name img {
  margin-top: 0;
}

.file-item:hover {
  background-color: transparent;
}

.file_info_box:hover {
  background-color: #f0f4fc;
}

.file-item-active {
  background-color: #f0f4fc;
}

.file-name {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  margin-left: 0;
}

.file-name-word {
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333;
  width: 60%;
  cursor: pointer;
}

#checkbox_all_btn {
  width: 14px;
  height: 14px;
  background: #4383f0;
  display: block;
  position: relative;
  border: 1px solid #4383f0;
  border-radius: 2px;
}

#checkbox_all_btn::after {
  content: "";
  width: 10px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 2px;
  top: 6px;
  z-index: 1;
}

#checkbox_all_box {
  display: none;
}

#checkbox_all {
  height: 40px;
  width: 124px;
  margin-left: 35px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}

#checkbox_all span {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #4383f0;
  margin: 0 10px;
}

.files-table {
  width: calc(100% - 35px);
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.cloud_file_header {
  margin-top: 20px;
}

.cloud_file_header_checked_box {
  display: flex;
  align-items: center;

  /deep/ .el-checkbox {
    .el-checkbox__label {    
      color: #1D1D1D;
    }
  }
}

.cloud-btns {
  width: auto;
  margin-left: 20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;

  /deep/ .el-button {
    border: none;
    color: #313131;
    background: #ECECEC;

    &:hover, &:focus {
      background-color: #ECECEC;
    }

    &.is-disabled.is-plain, &.is-disabled.is-plain:focus, &.is-disabled.is-plain:hover {
      color: #B4B6B8;
      background: #ECECEC;
    }

    &.save_to_cloud {
      color: #fff;
      background: #2072EC;
    }
  }

  .my_dwg_send_all {
    height: 34px;
    padding: 0 20px;
  }  
}

.cloud-btn {
  width: 90px;
  min-width: 90px;
  height: 32px;
  border-radius: 4px;
  border: solid 1px #4383f0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.cloud-btn:first-child {
  width: 138px;
}

.cloud-btn-img {
  width: 16px;
  height: 16px;
}

.cloud-btn-img img {
  width: 100%;
}

.cloud-btn-name {
  font-size: 14px;
  color: #4383f0;
  margin-left: 5px;
}

.cloud-btn-no {
  opacity: 0.8;
  cursor: not-allowed;
  border: solid 1px #979797;
}

.cloud-btn:hover {
  border: 1px solid #4383f0;
}

.cloud-btn:hover .cloud-btn-name {
  color: #4383f0;
}

.cloud-btn-no:hover {
  border: 1px solid #979797;
  color: #979797;
}

.cloud-btn-no:hover .cloud-btn-name {
  color: #979797;
}

.cloud-btn-no .cloud-btn-name {
  color: #999;
}

.shixiao_box {
  width: 100%;
  height: calc(100% - 104px);
  /* position: relative; */
}

.shixiao_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 224px;
  height: 186px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.shixiao_center_title1 {
  height: 24px;
  font-family: PingFangSC-Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 16px;
}

.shixiao_center_title2 {
  text-align: center;
  height: 16px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
}

.shixiao_center_img {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#share_lists_items {
  height: calc(100% - 240px);
  overflow-y: auto;
}

.file-operate {
  flex: 1;
}

.file-item-btns-box {
  display: block;
  height: 30px;
  background-color: transparent;
  overflow: hidden;
}

.file-item-btns {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-item-btn {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  background: url('@/assets/images/share_page/save_cloud_n.png') no-repeat center;

  &:hover {
    background-image: url('@/assets/images/share_page/save_cloud_y.png');
  }

  &:first-of-type {
    margin-left: 0;
  }

  &.file-item-down {
    background-image: url('@/assets/images/share_page/down_local_n.png');

    &:hover {
      background-image: url('@/assets/images/share_page/down_local_y.png');
    }
  }

  &.file-item-open {
    background-image: url('@/assets/images/share_page/open_n.png');

    &:hover {
      background-image: url('@/assets/images/share_page/open_y.png');
    }
  }
}

.file-item-btn i{
  font-size: 20px;
  color: #999;
}

.file-item-btn i:hover {
  color: #4383f0;
}

.file-item-btn img {
  width: 20px;
  height: 20px;
}

.my_dwg_top_btn {
  width: 90px;
  min-width: 90px;
  height: 34px;
  padding: 0;
}
</style>