import request from '@/utils/request'
import qs from 'qs'
// 用户账号登入
export const login = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/login/loginnew',
    data: qs.stringify(data)
  })
}

export const set_recover = (data) => {
  return request({
    async: false,
    method: 'POST',
    url: '/cloud/dwgapi/set_recover',
    data: qs.stringify(data)
  })
}

export const set_recover_status = (data) => {
  return request({
    async: false,
    method: 'POST',
    url: '/cloud/dwgapi/set_recover_status',
    data: qs.stringify(data)
  })
}


export const get_status_tz = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/online/get_status_tz',
    data: qs.stringify(data)
  })
}

export const checkdwg = (data) => {
  return request({
    async: false,
    method: 'POST',
    url: '/cloud/api/checkdwg',
    data: qs.stringify(data)
  })
}

export const cloudlink_getinfo_web = (data) => {
  return request({
    method: 'POST',
    url: '/api/cloudlink/cloudlink_getinfo_web',
    data: qs.stringify(data)
  })
}

//APP图纸中扫码wap
export const cancel_open = (data) => {
  return request({
    method: 'POST',
    url: '/api/openweb/cancel_open',
    data: qs.stringify(data)
  })
}

//图块 最近使用
export const get_recent_block = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/galleryrecent/get_recent_block',
    data: qs.stringify(data)
  })
}

//图块 最近使用
export const get_recent_dwginfo = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/galleryrecent/get_recent_dwginfo',
    data: qs.stringify(data)
  })
}


// 图块 保存最近使用
export const save_recent_block = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/galleryrecent/save_recent_block',
    data: qs.stringify(data)
  })
}

//图块 收藏夹列表
export const get_u_f = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallerycollect/get_u_f',
    data: qs.stringify(data)
  })
}

//图块 选中收藏夹-当前图块
export const f_collect_dwg_upload_stream = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallerycollect/f_collect_dwg_upload_stream',
    data: qs.stringify(data)
  })
}

//图块 选中收藏夹-当前图块-收藏到
export const f_collect_dwg_upload_stream_resend = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallerycollect/f_collect_dwg_upload_stream_resend',
    data: qs.stringify(data)
  })
}

//图块 选中收藏夹 图库
export const f_collect_dwg = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallerycollect/f_collect_dwg',
    data: qs.stringify(data)
  })
}

// 图块 取消收藏
export const f_uncollect_dwg = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallerycollect/f_uncollect_dwg',
    data: qs.stringify(data)
  })
}

// 图块 搜索
export const g_search = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallery/g_search',
    data: qs.stringify(data)
  })
}


//图块 新建收藏夹列表
export const favorites_add = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallerycollect/favorites_add',
    data: qs.stringify(data)
  })
}

//图块 删除收藏夹列表
export const favorites_del = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallerycollect/favorites_del',
    data: qs.stringify(data)
  })
}

//图块 收藏列表
export const get_f_dwg = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallerycollect/get_f_dwg',
    data: qs.stringify(data)
  })
}

//图块 收藏列表
export const f_search = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallerycollect/f_search',
    data: qs.stringify(data)
  })
}


//是否vip
export const is_vip = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/usercenter/is_vip',
    data: qs.stringify(data)
  })
}


//保存
export const save_dwg = (url, data) => {
  return request({
    method: 'POST',
    url: url,
    data: data
  })
}

// 打开备份后清除备份
export const clean_bak_log = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/online/clean_bak_log',
    data: qs.stringify(data)
  })
}

//设置用户设置
export const save_user_setting = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/dwgapi/save_user_setting',
    data: data
  })
}

// 保存图纸 标签
export const save_dwg_tag = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/dwgapi/save_file_tag',
    data: data
  })
}

export const create_filepos = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/dwgapi/create_filepos',
    data: data
  })
}

// 进入一个图库
export const get_g_cs = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallery/get_g_cs',
    data: data
  })
}

// 进入一个图库获取图块
export const get_g_cs_dwg = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/gallery/get_g_cs_dwg',
    data: data
  })
}

// 手机打开图纸获取code
export const get_opencode = (data) => {
  return request({
    method: 'POST',
    url: '/api/openweb/get_opencode',
    data: data
  })
}

// 手机打开图纸获取code status
export const get_opencode_status = (data) => {
  return request({
    method: 'POST',
    url: '/api/openweb/get_opencode_status',
    data: data
  })
}

// 生成图纸对比id
export const create_compare = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/compare/create_compare',
    data: qs.stringify(data)
  })
}

// 获取图纸对比文件下载链接
export const get_compare = (data) => {
  return request({
    method: 'POST',
    url: '/cloud/compare/get_compare',
    data: qs.stringify(data)
  })
}


var statistic_arr_ing = []
var is_share_use_drawbtn = false;
var need_share_use_drawbtn = [
  "click_dwgsee_btn_1",
  "click_dwgsee_btn_2",
  "click_dwgsee_btn_3",
  "click_dwgsee_btn_4",
  "click_dwgsee_btn_5",
  "click_dwgsee_btn_6",
  "click_dwgsee_btn_7",
  "click_dwgsee_btn_8",
  "click_dwgsee_btn_9",
  "click_dwgsee_btn_10",
  "click_dwgsee_btn_11",
  "click_dwgsee_btn_12",
  "click_dwgsee_btn_13",
  "click_dwgsee_btn_14",
  "click_dwgsee_btn_15",
  "click_dwgsee_btn_16",
  "click_dwgsee_btn_17",
  "click_dwgsee_btn_18",
  "click_dwgsee_btn_19",
  "click_dwgsee_btn_20",
  "click_dwgsee_btn_21",
  "click_dwgsee_btn_22",
  "click_dwgsee_btn_23",
  "click_dwgsee_btn_24",
  "click_dwgsee_btn_25",
  "click_dwgsee_btn_26",
  "click_dwgsee_btn_27",
  "click_dwgsee_btn_28",
  "click_dwgsee_btn_29",
  "click_dwgsee_btn_30",
  "click_dwgsee_btn_31",
  "click_dwgsee_btn_32",
  "click_dwgsee_btn_33",
  "click_dwgsee_btn_34",
  "click_dwgsee_btn_35",
  "click_dwgsee_btn_36",
  "click_dwgsee_btn_37"
]
export const statistic = (type, file_id = '', share_id = '') => {
  if (share_id != '') {
    if (!is_share_use_drawbtn && need_share_use_drawbtn.indexOf(type)!=-1) {
      // 打开分享图纸后使用测量绘图等按钮功能  本次打开只统计一次
      is_share_use_drawbtn = true;
      statistic('share_use_drawbtn', file_id, share_id);
    }
  }
  if (statistic_arr_ing.length == 0) {
    statistic_arr_ing.push({
      type: type,
      time: Math.trunc(new Date().getTime() / 1000) - 0
    })
  } else {
    var is_timer = false
    statistic_arr_ing.forEach((item) => {
      var newTime = Math.trunc(new Date().getTime() / 1000) - 0
      if (item.type == type) {
        if (newTime - item.time <= 3) {
          is_timer = true
          return
        } else {
          item.type = type
          item.time = newTime
          is_timer = false
        }
      } else {
        item.type = type,
          item.time = newTime
      }
    })
  }

  if (is_timer) {
    return
  }

  return request({
    method: 'POST',
    url: '/cloud/tjdata/statistic',
    data: {
      'type': type,
      'file_id': file_id,
      'share_id': share_id
    }
  })
}

