import { render, staticRenderFns } from "./batch_share_dwg.vue?vue&type=template&id=395b4280&scoped=true&"
import script from "./batch_share_dwg.vue?vue&type=script&lang=js&"
export * from "./batch_share_dwg.vue?vue&type=script&lang=js&"
import style0 from "./batch_share_dwg.vue?vue&type=style&index=0&id=395b4280&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "395b4280",
  null
  
)

export default component.exports